import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import me from "../images/dab.png"


const AboutMePage = () => (
    <Layout>
        <SEO title="About Me"/>
        <div className="spacer">
            &nbsp
        </div>
        <h2>About me :)</h2>
        <div style={{
            display: 'flex'
        }}>
        <p style= {{
            maxWidth: 960,
        }}> 
        I'm currently in my 4th year at the University of Maryland as an Information Science major specializing in Data Science with a minor in Statistics. 
        I will be graduating Spring 2022. I primarily program in JavaScript and enjoy front end web development. At UMD, I am a part of the Mighty Sound of Maryland 
        marching band's colorguard. Additionally, I like to spend time playing piano and composing music.
        </p>
        <img src={me} style={{
            width: '250px',
            hieght: 'auto'
        }}/>
        </div>
    </Layout>
)

export default AboutMePage